export default {
    props: {
        item: { type: Object },
        services: {type: Object}
    },
    methods: {
        removeDomain(index) {
            this.item.domains.splice(index, 1)
        },
        addDomain() {
            this.item.domains.push('')
        },
        async save() {
            var isValid = await this.$refs.observer.validate()
            if (isValid) {
                this.services.updateValidDomain({ domains: this.item.domains}).then(resp => {
                    if (!resp.error) {
                        this.$emit('onCompleted')
                    }
                })
            }
        },
    },
}